
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    name: "home-sso",
    components: {},
    setup() {
        const store = useStore();
        return { store };
    },
    mounted() {
        //console.log( process.env.VUE_APP_SAML_LOGIN_URL)
    },
    methods: {
        samlLogin() {
            window.location.href = process.env.VUE_APP_SAML_LOGIN_URL as any;
        },
    },
});

<template>
    <div class="d-flex justify-content-center align-items-center mt-5 mb-15">
        <div class="d-flex flex-column align-items-center">
            <div v-if="store.getters.currentSettings.is_sso_activated">
                <div v-if="store.getters.currentSettings.sso_type === 'SAML'">
                    <button
                        class="d-flex align-items-center justify-content-center btn btn-primary btn-block mb-6 fixed-width fixed-height"
                        @click="samlLogin">
                        <div class="d-flex justify-content-center align-items-center">
                            <i class="fas fa-user" style="font-size: 16px;"></i>
                            <span class="ml-2 font-size-large">{{ $t("auth.loginHomeUser") }}</span>
                        </div>
                    </button>
                </div>
                <div v-else>
                    <router-link to="/auth/callback"
                        class="d-flex align-items-center justify-content-center btn btn-primary btn-block mb-6 fixed-width fixed-height">
                        <div class="d-flex justify-content-center align-items-center">
                            <i class="fas fa-user" style="font-size: 16px;"></i>
                            <span class="ml-2 font-size-large">{{ $t("auth.loginHomeUser") }}</span>
                        </div>
                    </router-link>
                </div>
            </div>
            <div v-else>
                <router-link to="/sign-in"
                    class="d-flex align-items-center justify-content-center btn btn-primary btn-block mb-6 fixed-width fixed-height">
                    <div class="d-flex justify-content-center align-items-center">
                        <i class="fas fa-user" style="font-size: 16px;"></i>
                        <span class="ml-2 font-size-large">{{ $t("auth.loginHomeUser") }}</span>
                    </div>
                </router-link>
            </div>
            <router-link to="/sign-in"
                class="d-flex align-items-center justify-content-center btn btn-secondary btn-block fixed-width fixed-height">
                <div class="d-flex justify-content-center align-items-center">
                    <i class="fas fa-user-cog" style="font-size: 16px;"></i>
                    <span class="ml-4 font-size-large">{{ $t("auth.loginHomeAdmin") }}</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<style>
.fixed-width {
    width: 300px;
}

.fixed-height {
    height: 50px;
}

.font-size-large {
    font-size: 16px;
    margin-left: 8px;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    name: "home-sso",
    components: {},
    setup() {
        const store = useStore();
        return { store };
    },
    mounted() {
        //console.log( process.env.VUE_APP_SAML_LOGIN_URL)
    },
    methods: {
        samlLogin() {
            window.location.href = process.env.VUE_APP_SAML_LOGIN_URL as any;
        },
    },
});
</script>
